<template>
	<div class="register pos-rel">
		<div class="main pos-center">
			<div class="top flex jc-between">
				<div>
					<img class="mt-30 ml-30" src="../../assets/osImg/02daa407ebf2852d208fbd70968026d.png" alt="" />
				</div>
				<div class="login">
					<router-link to="/login">使用已有账户登录</router-link>
				</div>
			</div>

			<div class="fromBox pos-rel">
				<div class="from pos-center">
					<el-form ref="form" :model="form" :rules="rules" label-width="80px">
						<el-form-item prop="name">
							<el-input placeholder="设置用户名称" v-model="form.name">
								<i slot="prefix" class="el-icon-user"></i>
							</el-input>
						</el-form-item>
						<el-form-item prop="mobile">
							<el-input placeholder="输入手机号码" v-model="form.mobile">
								<i slot="prefix" class="el-icon-mobile-phone"></i>
							</el-input>
						</el-form-item>
						<el-form-item class="flex duanxin" prop="smsCode">
							<el-input placeholder="短信验证码" v-model="form.smsCode">
								<i slot="prefix" class="el-icon-chat-dot-round"></i>
							</el-input>

							<el-button @click="getSms()">{{smsText}}</el-button>
						</el-form-item>

						<el-form-item prop="pwd">
							<el-input placeholder="设置8至20位包含字母和数字的密码" v-model="form.pwd">
								<i slot="prefix" class="el-icon-lock"></i>
							</el-input>
						</el-form-item>
						<el-form-item prop="pwd1">
							<el-input placeholder="请再次输入登录密码" v-model="form.pwd1">
								<i slot="prefix" class="el-icon-lock"></i>
							</el-input>
						</el-form-item>
					</el-form>
					<div>
						<el-checkbox v-model="checked">已阅读并同意
							<span class="text">《用户服务协议》</span>
						</el-checkbox>
					</div>
					<div class="text-center mt-10">
						<el-button type="primary" @click="submitForm('form')">提交</el-button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import caches from "@cache/cache.js"
	var timer;
	let reg = /^(?=.*[A-Za-z])[A-Za-z0-9]{8,20}$/;
	export default {
		data() {
			var validatePass = (rule, value, callback) => {
				if (value === "") {
					callback(new Error("请输入密码"));
				} else if (!reg.test(value)) {
					callback(new Error("密码过于简单"));
				} else {
					if (this.form.pwd1 !== "") {
						this.$refs.form.validateField("pwd1");
					}
					callback();
				}
			};
			var validatePass2 = (rule, value, callback) => {
				if (value === "") {
					callback(new Error("请再次输入密码"));
				} else if (value !== this.form.pwd) {
					callback(new Error("两次输入密码不一致!"));
				} else {
					callback();
				}
			};
			return {
				times: 60,
				smsText: '获取验证码',
				form: {
					name: "",
					mobile: "",
					smsCode: "",
					pwd: "",
					pwd1: "",
				},
				rules: {
					name: [{
						required: true,
						message: "请输入名字",
						trigger: "blur"
					}],
					mobile: [{
						required: true,
						message: "请输入手机号码",
						trigger: "blur"
					}, ],
					smsCode: [{
						required: true,
						message: "请输入验证码",
						trigger: "blur"
					}],
					pwd: [{
						validator: validatePass,
						trigger: "blur"
					}],
					pwd1: [{
						validator: validatePass2,
						trigger: "blur"
					}],
				},
				checked: true,
			};
		},
		methods: {
			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.$post("/user-api/register/uv-register", this.form).then((data) => {
							if (data.code != 1000) {
								this.$message.error(data.msg);
								return;
							}
							this.$message({
								message: "注册成功",
								type: "success",
							});

						})
					} else {

					}
				});
			},
			getSms() {
				let _this = this;
				if (this.times != 60) {
					this.$message('请稍后再试')
					return
				}
				this.$post('/user-api/register/mobileCode/get?mobile=' + this.form.mobile).then(data => {
					if (data.code != 1000) {
						this.$message.error(data.msg)
						return
					}
					this.$message({
						message: "发送成功",
						type: "success",
					});
					timer = setInterval(function() {
						_this.smsText = _this.times + 's后重试'
						if (_this.times == 0) {
							_this.times = 60;
							clearInterval(timer);
							_this.smsText = '获取验证码'
						} else {
							_this.times--
						}
					}, 1000) //每隔1000毫秒弹一次
				})
			}
		},

	};
</script>

<style lang='scss' scoped>
	.register {
		background-color: #f5f5f5;
		width: 100vw;
		height: 100vh;

		.main {
			background-color: #fff;
			width: 1440px;
			height: 900px;
			box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
			border-radius: 4px;

			.fromBox {
				height: 700px;
			}

			.login {
				line-height: 108px;
				color: #359eff;
				margin-right: 30px;
			}

			.user {
				font-size: 18px;
				color: #999999;
			}

			.from {
				::v-deep .el-form-item__content {
					margin-left: 0 !important;
				}

				.duanxin {
					::v-deep .el-input {
						width: 234px;
					}

					::v-deep .el-button {
						margin-left: 6px;
					}
				}

				.text {
					color: #4a99f6;
				}

				width: 352px;
			}

			::v-deep .step {
				height: 126px;
				width: auto;
				background-color: #f5f5f5;
			}
		}
	}
</style>